import React from 'react';
import * as style from './Footer.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={style.footer} id="footer">
      <span>
        © {year} | Site by{' '}
        <a href="https://finnianlangham.com/" target="_blank" rel="noreferrer">
          Finnian Langham
        </a>
      </span>
    </footer>
  );
};

Footer.displayName = 'Footer';

export default Footer;
