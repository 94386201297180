import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './Header.module.scss';
import FadeIn from '../FadeIn/FadeIn';

export default function Header({ delay }) {
  const data = useStaticQuery(graphql`
    query {
      sanityAbout {
        greeting
        hero_image {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  `);

  return (
    <div className={style.header}>
      <FadeIn delay={delay}>
        <h3>{data.sanityAbout.greeting}</h3>
      </FadeIn>
      <FadeIn delay={delay + delay}>
        <h1>ctrl + me</h1>
      </FadeIn>
      <FadeIn delay={delay + delay * 2}>
        <GatsbyImage
          className={style.image}
          image={data.sanityAbout.hero_image.asset.gatsbyImageData}
          alt={data.sanityAbout.hero_image.alt || 'Michael Vince Moin'}
        />
      </FadeIn>
    </div>
  );
}
