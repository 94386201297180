// extracted by mini-css-extract-plugin
export var sectionTitle = "Projects-module--sectionTitle--2Bv2e";
export var container = "Projects-module--container--vkHsX";
export var toggleProjects = "Projects-module--toggleProjects--3VYE-";
export var plusMinus = "Projects-module--plusMinus--1oZuy";
export var minus = "Projects-module--minus--3IMAI";
export var plus = "Projects-module--plus--2EFOo";
export var open = "Projects-module--open--3bESk";
export var projects = "Projects-module--projects--3EcmW";
export var project = "Projects-module--project--2tzlO";
export var title = "Projects-module--title--2FSjd";
export var artwork = "Projects-module--artwork--331dU";