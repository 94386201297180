import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header/Header';
import About from '../components/About/About';
import Contact from '../components/Contact/Contact';
import Content from '../components/Content/Content';
import Projects from '../components/Projects/Projects';
// import Media from '../components/Media/Media';
import Footer from '../components/Footer/Footer';
import Meta from '../components/Meta/Meta';

export default function Home() {
  const delayStagger = 0.15;

  return (
    <>
      <Meta />
      <Content>
        <Header delay={delayStagger} />
        <div>
          <About delay={delayStagger + delayStagger * 3} />
          <Projects delay={delayStagger + delayStagger * 4} />
          <Contact delay={delayStagger + delayStagger * 5} />
        </div>
      </Content>
    </>
  );
}

export const query = graphql`
  query {
    sanityAbout {
      greeting
    }
    sanityContact {
      email
      contact_text
    }
    sanityMeta {
      title
      description
    }
  }
`;
