import React from 'react';
import { motion } from 'framer-motion';

export default function FadeIn({ delay, children }) {
  return (
    <motion.div
      initial={{ y: 24, opacity: 0, scale: 0.9 }}
      animate={{ y: 0, opacity: 1, scale: 1 }}
      transition={{ delay: delay || 0, duration: 1, ease: 'circOut' }}
    >
      {children}
    </motion.div>
  );
}
