import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function Meta({ title }) {
  const data = useStaticQuery(graphql`
    query {
      sanityMeta {
        title
        og_image {
          asset {
            url
          }
        }
        favicon {
          asset {
            url
          }
        }
        description
      }
    }
  `);

  return (
    <Helmet>
      <title>{data.sanityMeta.title}</title>
      <meta name="description" content={data.sanityMeta.description} />
      <link rel="icon" type="image/png" href={data.sanityMeta.favicon.asset.url} />
      <meta property="og:title" content={data.sanityMeta.title} />
      <meta property="og:type" content="Website" />
      <meta property="og:url" content="https://michaelvincemoin.com" />
      <meta property="og:description" content={data.sanityMeta.description} />
      <meta property="og:image" content={data.sanityMeta.og_image.asset.url} />
    </Helmet>
  );
}
