import { useStaticQuery, graphql } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import React from 'react';
import * as style from './About.module.scss';
import Section from '../Section/Section';
import FadeIn from '../FadeIn/FadeIn';

export default function About({ delay }) {
  const data = useStaticQuery(graphql`
    query {
      sanityAbout {
        _rawAboutText
      }
    }
  `);

  const aboutText = data.sanityAbout._rawAboutText;

  return (
    <Section>
      <FadeIn delay={delay}>
        <PortableText className={style.aboutText} blocks={aboutText} />
      </FadeIn>
    </Section>
  );
}
