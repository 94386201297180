import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './Projects.module.scss';
import Section from '../Section/Section';
import FadeIn from '../FadeIn/FadeIn';

function ProjectTile({ project }) {
  return (
    <a href={project.link} target="_blank" className={style.project} rel="noreferrer noopener">
      <GatsbyImage
        className={style.artwork}
        image={project.image.asset.gatsbyImageData}
        alt={`Album art for ${project.title} by ${project.artist}`}
      />
      <p className={style.title}>
        {project.artist} - {project.title} [{project.year}]
      </p>
    </a>
  );
}

export default function IAmA({ delay }) {
  const data = useStaticQuery(graphql`
    query {
      allSanityProject {
        nodes {
          artist
          title
          link
          year
          image {
            asset {
              gatsbyImageData(placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `);

  const projects = data.allSanityProject.nodes;

  return (
    <Section>
      <FadeIn delay={delay}>
        {/* <h2 className={style.sectionTitle}>Projects</h2> */}
        <div className={style.projects}>
          {projects.map((project, i) => (
            <ProjectTile key={i} project={project} />
          ))}
        </div>
      </FadeIn>
    </Section>
  );
}
